<template>
  <div class="contrainer-fluid m-0 px-0 w-100" style="padding-top: 90px">
    <div class="row m-0 p-2 w-100 justify-content-center">
      <div
        class="col-lg-8 col-12 m-0 p-0"
        v-if="product_data.data.length !== 0"
      >
        <div class="row m-0 p-0 w-100">
          <div class="col-12 m-0 shadow p-3 mt-3 mb-3 bg-body rounded">
            <prodictImg
              :data="product_data.data"
              :key="product_data.data.length"
            />
            <!-- ---------------- brand name ------------------- -->

            <div class="mt-4 mx-0 p-0">
              <span class="brandBadge"> {{ product_data.data[0].brand }}</span>
              <p class="mx-0 my-3 p-0 productname">
                {{ product_data.data[0].prNameTH }}
              </p>
              <p class="productsku_mobile">
                รหัสสินค้า: {{ product_data.data[0].skCode || "-" }}
              </p>
            </div>
            <hr />
            <!-- --------------- short desc-------- -->
            <div v-html="product_data.data[0].shortDesc1TH || '-'"></div>
          </div>
          <p class="text-center m-0 p-0 abount-product">เกี่ยวกับสินค้า</p>
          <div class="col-12 m-0 shadow p-3 mt-3 mb-3 bg-body rounded">
            <div class="row m-0 p-0 w-100 justify-content-cneter">
              <!-- --------------------- tab ----------------- -->
              <div
                class="col-6 pb-2 text-center abount_current"
                v-for="(e, i) in abount_list"
                :key="i"
                @click="selectAbount(i)"
                :class="[abount_current === i ? 'active' : '']"
              >
                {{ e }}
              </div>
              <!-- ------------------ รายละเอียด --------------------------- -->
              <div class="col-12 w-100 mt-3" v-if="abount_current === 0">
                <div
                  class="m-0 p-0 w-100"
                  v-if="
                    product_data.data[0].longDescTH !== '' &&
                    product_data.data[0].longDescTH !== null
                  "
                >
                  <div
                    class="detail"
                    v-html="product_data.data[0].longDescTH"
                  ></div>
                </div>
                <p v-else class="text-center my-5">ไม่พบข้อมูล</p>
              </div>
              <div class="col-12 w-100 mt-3" v-else-if="abount_current === 1">
                <div class="m-0 p-0 w-100" v-if="product_att.length !== 0">
                  <div
                    class="row p-1"
                    v-for="(e, i) in product_att"
                    :key="i"
                    :class="even(i) ? '' : 'att-even'"
                  >
                    <!-- {{e}} -->
                    <div class="col-6 font-600">{{ e.attrlabelTH }}</div>
                    <div class="col-6">{{ e.OptionsTH }}</div>
                  </div>
                </div>
                <p v-else class="text-center my-5">ไม่พบข้อมูล</p>
              </div>
            </div>
          </div>

          <!-- -------------------------- สินค้าใกล้เคียง/ทดแทน ------------------- -->
          <!-- <p class="text-center m-0 p-0 abount-product">
            {{ s_or_b }}
          </p>
          <div class="col-12 m-0 p-3 mt-3 mb-3">
            <Similarproducts :data="product_similar" :key="similar_key" />
          </div> -->
        </div>
      </div>
      <div
        class="col-lg-8 col-12 m-0 p-0"
        v-else-if="load_product_data && product_data.data.length === 0"
      >
        <div
          class="d-flex m-0 p-0 align-items-center justify-content-center"
          style="height: 70vh"
        >
          <p class="text-center">ไม่พบข้อมูล</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import prodictImg from "@/components/ProductInfo/prodictImg.vue";
import Similarproducts from "@/components/ProductInfo/Similarproducts.vue";
import { youtube } from "../../service/mainservice";
import { useRouter, useRoute } from "vue-router";
const router = useRouter();
const route = useRoute();
import { ref, reactive, onMounted, inject, watch } from "vue";
let $Alert = inject("$Alert");

import { product_info } from "../../api/product";
let product_data = reactive({ data: [] });
let product_att = reactive([]);
let product_similar = reactive([]);
let abount_list = reactive(["รายละเอียด", "ข้อมูลเฉพาะ"]);
let abount_current = ref(0);
let load_product_data = ref(false);
let similar_key = ref(Date.now());
let s_or_b = ref("สินค้าใกล้เคียง/ทดแทน");
// watch(
//   () => route.query.skcode,
//   async (newId) => {
//     productInfo_data();
//     productInfo_att();
//     productInfo_btgt();
//   }
// );
onMounted(() => {
  productInfo_data();
  productInfo_att();
  productInfo_btgt();
});
const selectAbount = (val) => {
  abount_current.value = val;
};
const even = (i) => {
  if (i % 2 == 0) {
    return true;
  } else {
    return false;
  }
};
const productInfo_data = async () => {
  try {
    load_product_data.value = false;
    let { spcode, skcode } = route.query;
    // if (!!!skcode || !!!spcode) {
    //   throw "ไม่พบเลขที่สินค้า / สาขา";
    // }
    if (!!!skcode) {
      throw "ไม่พบเลขที่สินค้า";
    }
    let data = { mode: "data", spcode: spcode, skcode: skcode };
    let gat_product_info = await product_info(data);
    product_data.data = gat_product_info.data;
    let pd_img_list = [];
    if (product_data.data.length !== 0) {
      let { images, linkForVdo } = product_data.data[0];
      if (!!linkForVdo) {
        let vdo = youtube(linkForVdo);
        pd_img_list.push({
          type: "vdo",
          path: `https://www.youtube.com/embed/${vdo}`,
        });
      }
      if (!!images) {
        let imgList = String(images).split("|");
        for await (let v of imgList) {
          if (!!v) {
            pd_img_list.push({
              type: "img",
              path: `${process.env.VUE_APP_BASE_URL_IMG}web/${v}`,
            });
          }
        }
      }
      product_data.data[0].img_set = pd_img_list;
    }
    load_product_data.value = true;
  } catch (error) {
    console.log(error);
    load_product_data.value = true;
    $Alert.Error(error);
  }
};

const productInfo_att = async () => {
  try {
    let { spcode, skcode } = route.query;
    // if (!!!skcode || !!!spcode) {
    //   throw "ไม่พบเลขที่สินค้า / สาขา";
    // }
    if (!!!skcode) {
      throw "ไม่พบเลขที่สินค้า";
    }
    let data = { mode: "attribute", spcode: spcode, skcode: skcode };
    let gat_product_info = await product_info(data);

    product_att = gat_product_info.data;
  } catch (error) {
    console.log(error);
    $Alert.Error(error);
  }
};

const productInfo_btgt = async () => {
  try {
    product_similar = [];
    let { spcode, skcode } = route.query;
    // if (!!!skcode || !!!spcode) {
    //   throw "ไม่พบเลขที่สินค้า / สาขา";
    // }
    if (!!!skcode) {
      throw "ไม่พบเลขที่สินค้า";
    }
    let data = { mode: "", spcode: spcode, skcode: skcode };
    let gat_product_info = await product_info(data);

    let gat_product_info_list = [];
    for await (let v of gat_product_info.data) {
      if (String(v.Type).trim().toLocaleUpperCase() === "S") {
        s_or_b.value = "สินค้าใกล้เคียง/ทดแทน";
      } else if (String(v.Type).trim().toLocaleUpperCase() === "B") {
        s_or_b.value = "สินค้าใช้งานร่วมกัน";
      }
      v.ImageForFeed_url = `${process.env.VUE_APP_BASE_URL_IMG}web/${v.ImageForFeed}`;
      gat_product_info_list.push(v);
    }
    // console.log(gat_product_info_list);
    product_similar = gat_product_info_list;
    similar_key.value = Date.now();
  } catch (error) {
    console.log(error);
    $Alert.Error(error);
  }
};
</script>

<style></style>
