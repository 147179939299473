import Swal from "sweetalert2";
export const Error = (message) => {
    return new Promise(async (resolve, reject) => {
        try {
            Swal.fire({
                icon: "error",
                title: "Error",
                text: message,
                confirmButtonColor: "#dc3545",
            });
            resolve("OK");
        } catch (error) {
            reject(error);
        }
    })
}



export const Confirm = (message) => {
    return new Promise(async (resolve, reject) => {

        Swal.fire({
            icon: "question",
            iconHtml: "?",
            text: message,
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#4C1E64",
            cancelButtonColor: '#dc3545',
            confirmButtonText: "ยืนยัน",
            cancelButtonText: "ยกเลิก",

        }).then((result) => {
            if (result.value) {
                resolve(result.value)
            }
        }).catch(error => {
            reject(error)
        })



    })
}

export const Succress = (message) => {
    return new Promise(async (resolve, reject) => {
        try {
            Swal.fire({
                icon: "success",
                title: "Success",
                text: message,
                confirmButtonColor: "#4C1E64",
            });
            resolve("OK");
        } catch (error) {
            reject(error);
        }
    })
}
