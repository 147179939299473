<template>
  <div id="app" class="container-fluid m-0 p-0 w-100 h-100">
    <div class="row m-0 p-0 w-100">
      <div class="col-12 m-0 p-0">
        <HeaderProductInfo
          class="fixed-top"
          v-if="route.name === 'ProductInfo'"
        />
        <Header v-else-if="_authStore.auth" />
      </div>
      <div class="col-12 m-0 p-0 w-100"><router-view /></div>
    </div>
  </div>
</template>
<script setup>
// import { useLoading } from "vue3-loading-overlay";
// import "vue3-loading-overlay/dist/vue3-loading-overlay.css";
import { useLoading } from "vue-loading-overlay";
import { useRouter, useRoute } from "vue-router";
import { ref, inject, onMounted, watch, computed } from "vue";
const router = useRouter();
const route = useRoute();
// console.log(route.name);
import { storeToRefs } from "pinia";
import { authStore } from "./store/authStore";
let _authStore = authStore();
let { loading, auth } = storeToRefs(authStore());
let OnLoad = ref(loading);
import Header from "@/components/layout/Header.vue";
import HeaderProductInfo from "@/components/layout/HeaderProductInfo.vue";
//  console.log(process.env);

let key = ref(Date.now());
onMounted(() => {});
let checkLoading = computed(() => {
  return _authStore.loading;
});
let _loader;
watch(checkLoading, (v) => {
  try {
    let loader = useLoading();
    if (v) {
      _loader = loader.show({
        color: "#4C1E64",
        width: 70,
        height: 70,
        loader: "bars",
        backgroundColor: "#ffffff",
        opacity: 0.5,
        zIndex: 9999,
      });
    } else if (!v) {
      console.log("hide");
      setTimeout(() => {
        _loader.hide();
        console.log("hide Done");
      }, 200);
    } else {
      setTimeout(() => {
        console.log("hide time out");
        _loader.hide();
      }, 5000);
    }
  } catch (error) {
    console.log(error);
  }
  console.log(v);
});
</script>
<style></style>
