import * as http from '../service/APIservice'
import * as constant from '../service/constant'

let { call_API } = http
let { API_URL, } = constant


export const product_info = async (praam) => {
    return new Promise(async (resolve, reject) => {
        try {
            let data = praam
            let getdata = await call_API("post",
                `product/info`,
                data,
                0)
            resolve(getdata)
        } catch (error) {
            reject(error)
        }
    })

}
