import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap/dist/js/bootstrap.js"
import { createApp } from "vue";
import './styles/_main.scss'
import App from "./App.vue";
import router from "./router";
// import store from "./store";
import { createPinia } from 'pinia'
import * as  Alert from './service/Alert'

import {LoadingPlugin} from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';

const pinia = createPinia()
const app = createApp(App)
app.use(LoadingPlugin);
app.use(pinia)
// app.use(store);
app.use(router);

app.provide('$Alert', Alert);

app.mount('#app');
// createApp(App).use(store).use(router).mount("#app");