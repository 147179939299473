<template>
  <div class="m-0 p-0 w-100">
    <carousel
      :items-to-show="1"
      :wrap-around="data[0].img_set.length === 1 ? false : true"
      v-if="data[0]?.img_set !== undefined"
      v-model="currentSlide"
    >
      <slide v-for="(e, i) in data[0].img_set" :key="i">
        <!-- {{ e }} -->
        <div class=" ">
          <img
            v-if="e.type === 'img'"
            :src="e.path"
            alt=""
            class="img-fluid my-img"
          />
          <div v-else-if="e.type === 'vdo'" class="p-3 p-lg-0">
            <iframe
              style="max-width: 70vw"
              class=" "
              width=""
              height="300"
              :src="e.path"
              allowfullscreen
            ></iframe>
          </div>
          <!-- <video
          v-else-if="e.type === 'vdo'"
          :src="e.path"
          class="object-fit-scale"
          autoplay
        ></video> -->
        </div>
      </slide>

      <template #addons>
        <navigation />
        <!-- <pagination /> -->
      </template>
    </carousel>

    <div
      class="d-flex flex-row m-0 p-0 w-100 justify-content-center"
      v-if="data[0]?.img_set !== undefined"
    >
      <span
        class="dot mx-1"
        v-for="(e, i) in data[0].img_set"
        :key="i + 'dot'"
        :class="[i === currentSlide ? 'active' : '']"
        @click="slideTo(i)"
      ></span>
    </div>
  </div>
</template>

<script setup>
import "vue3-carousel/dist/carousel.css";
import { Carousel, Slide, Pagination, Navigation } from "vue3-carousel";
import { ref } from "vue";
const props = defineProps(["data"]);
let { data } = props;

let currentSlide = ref(0);
const slideTo = (val) => {
  currentSlide.value = val;
};
</script>
<style></style>
