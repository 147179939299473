import axios from "axios";
import * as constant from "./constant";
const API_URL = constant.API_URL;
const API_IMG_UPLOAD = constant.API_IMG_UPLOAD;

// import store from "../store/index";

// -------------------- pinia ---------------------
import { createPinia } from 'pinia';
import { createApp } from 'vue'
import App from '../../src/App.vue'

import { authStore } from '../store/authStore'
const pinia = createPinia()
const app = createApp(App)
app.use(pinia)
let useauth = authStore()
// -------------------- pinia ---------------------
// let { onloading, unloading } = useauth
import { Delay, readCookie, Base64, check_exp } from "./mainservice";
const { decode } = Base64;

export async function call_API(para_method, para_url, para_data, para_auth) {
  return new Promise((resolve, reject) => {
    var gethead;

    if (para_auth) {
      let getToken = decode(readCookie(constant.TOKEN_KEY));
      // let exp =check_exp(getToken)
      // console.log(exp);
      gethead = {
        Authorization: "Bearer " + getToken,
        "Content-Type": "application/json",
      };
    } else {
      gethead = {
        "Content-Type": "application/json",
      };
    }
    var data = JSON.stringify(para_data);

    var config = {
      method: para_method,
      url: API_URL + para_url,
      headers: gethead,
      data: data,
    };
    useauth.onloading()
    // store.dispatch("loadingActions/onload");
    axios(config)
      .then(function (response) {
        let getData = JSON.parse(JSON.stringify(response.data));
        // if(getData.data.dbcode !== 0) {
        //   reject(getData.data.dbmessage)
        // }
        // console.log(getData)

        // store.dispatch("loadingActions/onunload");
        useauth.unloading()
        resolve(getData);
      })
      .catch(function (error) {
        console.log(error);
        // store.dispatch("loadingActions/onunload");
        useauth.unloading()
        reject(error.response.data);
      });
  });
}
export async function call_API_NO_LOAD(para_method, para_url, para_data, para_auth) {
  return new Promise((resolve, reject) => {
    var gethead;

    if (para_auth) {
      let getToken = decode(readCookie(constant.TOKEN_KEY));
      // let exp =check_exp(getToken)
      // console.log(exp);
      gethead = {
        Authorization: "Bearer " + getToken,
        "Content-Type": "application/json",
      };
    } else {
      gethead = {
        "Content-Type": "application/json",
      };
    }
    var data = JSON.stringify(para_data);

    var config = {
      method: para_method,
      url: API_URL + para_url,
      headers: gethead,
      data: data,
    };

    // store.dispatch("loadingActions/onload");
    axios(config)
      .then(function (response) {
        let getData = JSON.parse(JSON.stringify(response.data));
        // if(getData.data.dbcode !== 0) {
        //   reject(getData.data.dbmessage)
        // }
        // console.log(getData)

        // store.dispatch("loadingActions/onunload");

        resolve(getData);
      })
      .catch(function (error) {
        console.log(error);
        store.dispatch("loadingActions/onunload");
        reject(error.response.data);
      });
  });
}

export async function call_API_FormData(
  para_method,
  para_url,
  para_data,
  para_auth
) {
  return new Promise((resolve, reject) => {
    var gethead;
    let getToken;
    if (para_auth) {
      let getHaveLocal = decode(readCookie(constant.TOKEN_KEY));
      if (!getHaveLocal) {
        window.location = "/login";
        return;
      }
      try {
        getToken = decode(readCookie(constant.TOKEN_KEY));
      } catch (error) {
        localStorage.removeItem(constant.TOKEN_KEY);
        window.location = "/login";
        return;
      }
      gethead = {
        Authorization: "Bearer " + getToken,
        "Content-Type": "multipart/form-data",
      };
    } else {
      gethead = {
        "Content-Type": "multipart/form-data",
      };
    }

    let config = {
      method: para_method,
      url: API_IMG_UPLOAD + para_url,
      headers: gethead,
      data: para_data,
    };

    axios(config)
      .then(function (response) {
        let getData = JSON.parse(JSON.stringify(response.data));
        // console.log(getData)
        resolve(getData);
      })
      .catch(function (error) {
        let getError = error.response.data;
        console.log(getError);
        if (getError.status_code && getError.status_code == 401) {
          localStorage.removeItem(constant.TOKEN_KEY);
          window.location = "/login";
        }
        reject(getError);
      });
  });
}

export async function call_API_Getfile(
  para_method,
  para_url,
  para_data,
  para_auth
) {
  return new Promise((resolve, reject) => {
    var gethead;
    if (para_auth) {
      let getToken = decode(readCookie(constant.TOKEN_KEY));
      gethead = {
        Authorization: "Bearer " + getToken,
        "Content-Type": "application/pdf",
      };
    } else {
      gethead = {
        "Content-Type": "application/pdf",
      };
    }
    var config = {
      method: para_method,
      url: API_URL + para_url,
      headers: gethead,
      responseType: "arraybuffer",
      // responseType: "blob", // for download file
    };
    store.dispatch("loadingActions/onload");
    axios(config)
      .then(function (response) {
        store.dispatch("loadingActions/onunload");
        resolve(response);
      })
      .catch(function (error) {
        store.dispatch("loadingActions/onunload");
        reject(error);
      });
  });
}

export async function call_API_Download(
  para_method,
  para_url,
  para_data,
  para_auth
) {
  return new Promise((resolve, reject) => {
    var gethead;
    if (para_auth) {
      let getToken = decode(readCookie(constant.TOKEN_KEY));
      gethead = {
        Authorization: "Bearer " + getToken,
        "Content-Type": "application/json",
      };
    } else {
      gethead = {
        "Content-Type": "application/json",
      };
    }

    var data = JSON.stringify(para_data);

    // var config = {
    //   method: para_method,
    //   url: API_URL + para_url,
    //   headers: gethead,
    //   data: data
    // };

    var config = {
      method: para_method,
      url: API_URL + para_url,
      headers: gethead,
      data: data,
      responseType: "blob", // for download file
    };

    store.dispatch("loadingActions/onload");
    axios(config)
      .then(function (response) {
        store.dispatch("loadingActions/onunload");
        resolve(response);
      })
      .catch(function (error) {
        store.dispatch("loadingActions/onunload");
        reject(error);
      });
  });
}

export async function call_API_GetfileCSV(
  para_method,
  para_url,
  para_data,
  para_auth
) {

  return new Promise((resolve, reject) => {
    var gethead;
    if (para_auth) {
      let getToken = decode(readCookie(constant.TOKEN_KEY));
      gethead = {
        Authorization: "Bearer " + getToken,
        "Content-Type": "application/pdf",
      };
    } else {
      gethead = {
        "Content-Type": "application/pdf",
      };
    }
    var config = {
      method: para_method,
      url: para_url,
      headers: gethead,
      responseType: "blob",
    };

    store.dispatch("loadingActions/onload");
    axios(config)
      .then(function (response) {
        store.dispatch("loadingActions/onunload");
        resolve(response);
      })
      .catch(function (error) {
        store.dispatch("loadingActions/onunload");
        reject(error);
      });
  });
}
