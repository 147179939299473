<template>
  <nav class="navbar  bg-body-tertiary m-0 p-0">
    <div class="container-fluid bg-main py-2">
      <div class="ms-auto"></div>
      <img
        src="../../assets/image/logo.svg"
        alt="Logo"
        class="d-inline-block align-text-top"
      />
      <div class="me-auto"></div>
    </div>
  </nav>
</template>

<script setup></script>

<style></style>
