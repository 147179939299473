import { defineStore } from 'pinia'
import * as constant from '../service/constant';
import * as main from '../service/mainservice'
let { TOKEN_KEY } = constant
let { readCookietoken, decodeJwt, saveCookiemintoken, removeCookie } = main

let _user = readCookietoken(TOKEN_KEY)
let init = {
    auth: _user ? true : false,
    user: _user ? _user : null,
    loading: false
}

export const authStore = defineStore({
    id: 'auth',
    state: () => (

        init
    ),
    actions: {
        onloading() {
            this.loading = true
            console.log(`onloading : ${this.loading}`);
        },
        unloading() {
            this.loading = false
            console.log(`onloading : ${this.loading}`);
        },
        async authLogin(data) {
            return new Promise(async (resolve, reject) => {
                try {
                    let tk = await decodeJwt(data)
                    let iat = new Date()
                    let exp = await timestamp(tk.exp)
                    let diff = (exp - iat) / 1000 / 60
                    // -------------- timestamp------------------
                    saveCookiemintoken(TOKEN_KEY, data, diff)
                    let getUser = readCookietoken(TOKEN_KEY)

                    this.auth = true
                    this.user = getUser
                    resolve('OK')
                } catch (error) {
                    reject(error)
                }

            })

        },

        async signout() {
            return new Promise(async (resolve, reject) => {
                try {
                    deleteAllCookies()
                    this.auth = false
                    this.user = null
                    resolve('OK')
                } catch (error) {
                    reject(error)
                }

            })
        }




    },
    getters: {
        // oddOrEven: (state) => {
        //   if(state.count % 2 === 0) return 'even'
        //   return 'odd'
        // }
    },
})


function timestamp(in_time) {
    return new Promise((resolve, reject) => {
        let unix_timestamp = in_time
        var date = new Date(unix_timestamp * 1000);

        var year = date.getFullYear();
        var month = date.getMonth();
        var day = date.getDate();

        var hours = date.getHours();

        var minutes = "0" + date.getMinutes();

        var seconds = "0" + date.getSeconds();

        var time = `${year}-${month}-${day} ${hours + ':' + minutes.substr(-2) + ':' + seconds.substr(-2)}`;
        let formattedTime = new Date(time.split(' ')[0].split('-')[0], time.split(' ')[0].split('-')[1], time.split(' ')[0].split('-')[2], time.split(' ')[1].split(':')[0], time.split(' ')[1].split(':')[1], time.split(' ')[1].split(':')[2])
        resolve(formattedTime)
    });

}

function deleteAllCookies() {
    var cookies = document.cookie.split(";");

    for (var i = 0; i < cookies.length; i++) {
        var cookie = cookies[i];
        var eqPos = cookie.indexOf("=");
        var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
        document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
    }
}